import { useSelector } from "react-redux";

import { tRootState } from "../../store";

import { Navigate } from "react-router-dom";

export const withUnAuth =
    <P extends object>(Component: React.ComponentType) =>
    (props: P) => {
        const { accessToken, userDetails } = useSelector(
            (state: tRootState) => state.user
        );

        if (accessToken || userDetails) return <Navigate to="/" />;

        return <Component {...props} />;
    };

const withAuth =
    <P extends object>(Component: React.ComponentType) =>
    (props: P) => {
        const { accessToken, userDetails } = useSelector(
            (state: tRootState) => state.user
        );

        if (!accessToken || !userDetails) return <Navigate to="/login" />;

        return <Component {...props} />;
    };

export default withAuth;
