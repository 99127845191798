import { useState } from "react";

import { IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";

export type SelectBoxDataType = {
    key: string;
    value: { img?: string; text: string };
}[];

type SelectBoxType = {
    title: string;
    data: SelectBoxDataType;
    selected: string;
    handler: (value: string) => void;
};

const SelectBox = ({ title, data, selected, handler }: SelectBoxType) => {
    const [search, setSearch] = useState<string>("");

    data = data.filter((dt) => new RegExp(search, "i").test(dt.value.text));

    return (
        <div className="select-box">
            <div className="select-box__main">
                <div className="select-box__header">
                    <div></div>
                </div>
                <h3 className="select-box__heading">{title}</h3>
                <div className="select-box__search">
                    <span>
                        <IonIcon icon={searchOutline} />
                    </span>
                    <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="select-box__options">
                    {data.map((dt) => {
                        return (
                            <div
                                className="select-box__option"
                                key={dt.key}
                                onClick={() => handler(dt.key)}
                            >
                                {dt.value.img ? (
                                    <img
                                        src={dt.value.img}
                                        alt=""
                                        className="select-box__img"
                                    />
                                ) : null}
                                <p>{dt.value.text}</p>
                                <input
                                    type="radio"
                                    className="select-box__radio"
                                    disabled
                                    checked={selected === dt.key}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SelectBox;
