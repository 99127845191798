import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { cart as cartIcon, cartOutline, trashOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tBook } from "../../store/types/app.types";

import useFunctions from "../../hooks/useFunctions/useFunctions";
import { useNavigate } from "react-router-dom";

const WishlistBook = ({ book }: { book: tBook }) => {
    const navigate = useNavigate();

    const {
        cart,
        addingCart,
        removedCart,

        removedWishlist,
    } = useSelector((state: tRootState) => state.user);

    const { addToCart, removeFromCart, removeFromWishlist } = useFunctions();

    const inCart =
        (cart.findIndex((cartBook) => cartBook._id === book._id) !== -1 ||
            addingCart.findIndex((cartBook) => cartBook._id === book._id) !==
                -1) &&
        !removedCart.includes(book._id);

    const removed = removedWishlist.includes(book._id);

    if (removed) return null;

    return (
        <div className="cart-product">
            <div className="cart-product__img-block">
                <img
                    src={book.ThumbnailPath}
                    alt=""
                    className="cart-product__img"
                />
            </div>
            <div className="cart-product__main">
                <span className="cart-product__author">
                    {book.Authors[0].PenName}
                </span>
                <h3
                    className="cart-product__name"
                    onClick={() => navigate(`/book/${book._id}`)}
                >
                    {book.Name}
                </h3>
                <div className="cart-product__price-block">
                    <span>{book.Price.toFixed(2)} USD</span>
                </div>
                {book.AmazonLink ? (
                    <a
                        href={book.AmazonLink}
                        target="_blank"
                        rel="noreferrer"
                        className="cart-product__link"
                    >
                        Available on Amazon
                    </a>
                ) : null}
            </div>
            <div className="cart-product__right">
                <div className="cart-product__btns">
                    <button
                        className="cart-product__btn"
                        onClick={
                            inCart
                                ? (e) => removeFromCart(e, book)
                                : (e) => addToCart(e, book)
                        }
                    >
                        <IonIcon
                            icon={inCart ? cartIcon : cartOutline}
                            className={inCart ? "text-tertiary" : ""}
                        />
                    </button>
                    <button
                        className="cart-product__btn"
                        onClick={(e) => removeFromWishlist(e, book)}
                    >
                        <IonIcon icon={trashOutline} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WishlistBook;
