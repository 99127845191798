import { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tBook } from "../../store/types/app.types";

import useData from "../useData/useData";
import {
    addCartProduct,
    addProductToCart,
    addProductToWishlist,
    addWishlistProduct,
    removeCartProduct,
    removeProductFromCart,
    removeProductFromWishlist,
    removeWishlistProduct,
    undoAddCartProduct,
    undoAddWishlistProduct,
    undoRemoveCartProduct,
    undoRemoveWishlistProduct,
} from "../../store/userSlice";
import { addNotification } from "../../store/notificationsSlice";

const useFunctions = () => {
    const dispatch = useDispatch();

    const { accessToken, userDetails } = useSelector(
        (state: tRootState) => state.user
    );

    const loggedIn = accessToken && userDetails;

    const { fetchCart, fetchWishlist } = useData();

    const addToWishlist = (e: FormEvent<HTMLButtonElement>, book: tBook) => {
        const triggerBtn = e.target as HTMLButtonElement;

        triggerBtn.setAttribute("disabled", "disabled");

        if (!loggedIn) {
            dispatch(addProductToWishlist(book));
            triggerBtn.removeAttribute("disabled");
            return;
        }

        dispatch(addWishlistProduct(book));

        api_client({
            url: `/wishlist/books/${book._id}`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                return fetchWishlist();
            })
            .then((res) => {
                dispatch(undoAddWishlistProduct(book._id));

                return fetchCart();
            })
            .then((res) => {
                // all done
            })
            .catch((err) => {
                // do nothing
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
            });
    };

    const removeFromWishlist = (
        e: FormEvent<HTMLButtonElement>,
        book: tBook
    ) => {
        const triggerBtn = e.target as HTMLButtonElement;

        triggerBtn.setAttribute("disabled", "disabled");

        if (!loggedIn) {
            dispatch(removeProductFromWishlist(book._id));
            triggerBtn.removeAttribute("disabled");
            return;
        }

        dispatch(removeWishlistProduct(book._id));

        api_client({
            url: `/wishlist/books/${book._id}`,
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                return fetchWishlist();
            })
            .then((res) => {
                dispatch(undoRemoveWishlistProduct(book._id));

                return fetchCart();
            })
            .then((res) => {
                // all done
            })
            .catch((err) => {
                // do nothing
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
            });
    };

    const addToCart = (e: FormEvent<HTMLButtonElement>, book: tBook) => {
        if (book.InPurchases)
            return dispatch(
                addNotification({
                    notification: {
                        type: "warning",
                        text: "Book already purchased",
                    },
                })
            );

        const triggerBtn = e.target as HTMLButtonElement;

        triggerBtn.setAttribute("disabled", "disabled");

        if (!loggedIn) {
            dispatch(addProductToCart(book));
            triggerBtn.removeAttribute("disabled");
            return;
        }

        dispatch(addCartProduct(book));

        api_client({
            url: `/cart/books/${book._id}`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                return fetchCart();
            })
            .then((res) => {
                dispatch(undoAddCartProduct(book._id));

                return fetchWishlist();
            })
            .then((res) => {
                // all done
            })
            .catch((err) => {
                // do nothing
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
            });
    };

    const removeFromCart = (e: FormEvent<HTMLButtonElement>, book: tBook) => {
        const triggerBtn = e.target as HTMLButtonElement;

        triggerBtn.setAttribute("disabled", "disabled");

        if (!loggedIn) {
            dispatch(removeProductFromCart(book._id));
            triggerBtn.removeAttribute("disabled");
            return;
        }

        dispatch(removeCartProduct(book._id));

        api_client({
            url: `/cart/books/${book._id}`,
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                return fetchCart();
            })
            .then((res) => {
                dispatch(undoRemoveCartProduct(book._id));

                return fetchWishlist();
            })
            .then((res) => {
                // all done
            })
            .catch((err) => {
                // do nothing
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
            });
    };

    return { addToWishlist, removeFromWishlist, addToCart, removeFromCart };
};

export default useFunctions;
