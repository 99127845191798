import { Fragment, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tBooks } from "../../store/types/app.types";

import BookCard from "../BookCard/BookCard";
import BookCardLoader from "../BookCard/BookCardLoader";

const FeaturedBooks = () => {
    const accessToken = useSelector(
        (state: tRootState) => state.user.accessToken
    );

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [books, setBooks] = useState<tBooks>([]);

    useEffect(() => {
        api_client({
            url: "/books?featured=true&page=1&division=10",
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                setBooks(res.data.data.books);
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [accessToken]);

    if (!loading && !books.length) return null;

    return (
        <section className="books-section section-separate">
            <div className="container">
                <div className="books-section__h-main">
                    <h1 className="heading-primary">Featured Books</h1>
                    {/* <div className="pagination">
                        <button className="pagination__control pagination__control--hovered">
                            <IonIcon icon={chevronBackOutline} />
                        </button>
                        <button className="pagination__control">
                            <IonIcon icon={chevronForwardOutline} />
                        </button>
                    </div> */}
                </div>
                {loading ? (
                    <div className="books">
                        {new Array(10).fill(null).map((_, i) => {
                            return (
                                <Fragment key={i}>
                                    <BookCardLoader />
                                </Fragment>
                            );
                        })}
                    </div>
                ) : null}
                {!loading && !error && books.length ? (
                    <div className="books">
                        {books.map((book) => (
                            <Fragment key={book._id}>
                                <BookCard book={book} />
                            </Fragment>
                        ))}
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default FeaturedBooks;
