import { ChangeEvent, useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
    search,
    chevronBackOutline,
    chevronForwardOutline,
    filterOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tBooks } from "../../store/types/app.types";

import Page from "../../layouts/Page/Page";

import BookCard from "../../components/BookCard/BookCard";
import BookCardLoader from "../../components/BookCard/BookCardLoader";

import { getSkip } from "../../utils/func";

const Books = () => {
    const accessToken = useSelector(
        (state: tRootState) => state.user.accessToken
    );
    const { categories } = useSelector((state: tRootState) => state.cache);

    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get("query") || "";
    const category = searchParams.get("category") || "all";
    const page = searchParams.get("page") || "1";
    const division = searchParams.get("division") || "8";

    const fetchInterval = useRef<number | null>(null);
    const isFetching = useRef(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [pagination, setPagination] = useState(0);
    const [numRecords, setNumRecords] = useState(0);
    const [books, setBooks] = useState<tBooks>([]);

    const [showFilter, setShowFilter] = useState(false);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchParams({
            query: e.target.value,
            category: category,
            page,
            division,
        });
    };

    const handleCategoryChange = (cat: string) => {
        setSearchParams({
            query,
            category: cat,
            page: "1",
            division: "8",
        });
    };

    const nextPage = () => {
        setSearchParams((sp) => {
            return {
                query,
                category,
                page: String(+(sp.get("page") || "1") + 1),
                division: "8",
            };
        });
    };

    const prevPage = () => {
        setSearchParams((sp) => {
            return {
                query,
                category,
                page: String(+(sp.get("page") || "1") - 1),
                division: "8",
            };
        });
    };

    useEffect(() => {
        if (fetchInterval.current) window.clearInterval(fetchInterval.current);

        setLoading(true);

        fetchInterval.current = window.setInterval(() => {
            if (isFetching.current) return;

            isFetching.current = true;

            api_client({
                url: `/books/?search=${query}&categories=${category}&page=${page}&division=${division}`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => {
                    setPagination(res.data.data.meta_data.pagination);
                    setNumRecords(res.data.data.meta_data.num_records);
                    setBooks(res.data.data.books);
                })
                .catch((err) => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                    if (fetchInterval.current)
                        window.clearInterval(fetchInterval.current);
                    isFetching.current = false;
                });
        }, 3000);
    }, [query, category, page, division, accessToken]);

    const categoriesFiltered = categories.map((cat) => cat);
    categoriesFiltered.sort((cat1, cat2) => cat2.NumBooks - cat1.NumBooks);

    const skip = getSkip(+page, +division);
    const end = skip + +division;

    return (
        <Page>
            <div className="books-block container">
                <h3 className="books-block__heading">
                    {query ? `Search Results: ${query}` : "Books"}
                </h3>
                <div
                    className="books-block__filter"
                    onClick={() => setShowFilter((f) => !f)}
                >
                    <button className="books-block__filter-btn">
                        <IonIcon icon={filterOutline} />
                        Filter
                    </button>
                </div>
                <div className="books-block__main">
                    <div
                        className={`books-block__main-left ${
                            showFilter ? "flex-lrdesktop" : "hide-lrdesktop"
                        }`}
                    >
                        <div className="search-input">
                            <IonIcon icon={search} />
                            <input
                                type="text"
                                placeholder="Search"
                                value={query}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className="books-block-card">
                            <h3 className="books-block-card__heading">
                                Categories
                            </h3>
                            <div className="books-block__input-groups">
                                <div
                                    className={`check-block ${
                                        category === "all"
                                            ? "check-block--active"
                                            : ""
                                    }`}
                                    onClick={() => handleCategoryChange("all")}
                                >
                                    <input
                                        type="checkbox"
                                        checked={category === "all"}
                                        readOnly
                                    />
                                    <p>All</p>
                                    <p></p>
                                </div>
                                {categoriesFiltered.map((cat) => {
                                    return (
                                        <div
                                            className={`check-block ${
                                                category === cat._id
                                                    ? "check-block--active"
                                                    : ""
                                            }`}
                                            key={cat._id}
                                            onClick={() =>
                                                handleCategoryChange(cat._id)
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                checked={category === cat._id}
                                                readOnly
                                            />
                                            <p>{cat.Name}</p>
                                            <p>({cat.NumBooks})</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="books-block__main-right">
                        {loading ? (
                            <div className="books books--grid4">
                                {new Array(8).fill(null).map((_, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <BookCardLoader />
                                        </Fragment>
                                    );
                                })}
                            </div>
                        ) : null}
                        {error ? (
                            <div>
                                Error loading books at this time. Reload page
                            </div>
                        ) : null}
                        {!loading && !error && !books.length ? (
                            <div>No Results</div>
                        ) : null}
                        {!loading && !error && books.length ? (
                            <div className="books books--grid4">
                                {books.map((book) => (
                                    <Fragment key={book._id}>
                                        <BookCard book={book} />
                                    </Fragment>
                                ))}
                            </div>
                        ) : null}
                        {books.length ? (
                            <div className="pagination2 u-mt-small">
                                <p>
                                    Showing {skip + 1} -{" "}
                                    {end >= numRecords ? numRecords : end} of{" "}
                                    {numRecords} results
                                </p>
                                <div>
                                    {+page > 1 ? (
                                        <button onClick={prevPage}>
                                            <IonIcon
                                                icon={chevronBackOutline}
                                            />
                                        </button>
                                    ) : null}
                                    {+page < pagination ? (
                                        <button onClick={nextPage}>
                                            <IonIcon
                                                icon={chevronForwardOutline}
                                            />
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Books;
