import logo from "../../assets/images/logo.png";

const Preloader = () => {
    return (
        <div className="preloader">
            <div className="preloader__main">
                <img src={logo} className="preloader__logo" alt="" />
                <div className="dot-falling"></div>
            </div>
        </div>
    );
};

export default Preloader;
