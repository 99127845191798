const BookCardLoader = () => {
    return (
        <div className="book">
            <div className="book__header book__header--nohover placeholder"></div>
            <div className="book__main">
                <h4 className="book__name color-transparent placeholder">
                    Rick Dad Poor Dad
                </h4>
                <p className="book__author color-transparent placeholder">
                    Robert Kiyosaki
                </p>
                <div className="book__price">
                    <p className="color-transparent placeholder">$40.99</p>
                    <small className="color-transparent placeholder">
                        $140.99
                    </small>
                </div>
            </div>
        </div>
    );
};

export default BookCardLoader;
