import { Component } from "react";

import Preloader from "../../components/Preloader/Preloader";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // console.log(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;

        if (this.state.hasError) {
            localStorage.removeItem("appState");

            const interval = setInterval(() => {
                window.location.assign("/");
                clearInterval(interval);
            }, 3000);
        }

        return hasError ? <Preloader /> : this.props.children;
    }
}

export default ErrorBoundary;
