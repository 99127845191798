import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
    cartOutline,
    heartOutline,
    logoAmazon,
    heart,
    cart as cartIcon,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tBook } from "../../store/types/app.types";

import useFunctions from "../../hooks/useFunctions/useFunctions";

const BookCard = ({ book }: { book: tBook }) => {
    const navigate = useNavigate();

    const {
        cart,
        addingCart,
        removedCart,
        wishlist,
        addingWishlist,
        removedWishlist,
    } = useSelector((state: tRootState) => state.user);

    const { addToCart, removeFromCart, addToWishlist, removeFromWishlist } =
        useFunctions();

    const inWishlist =
        (wishlist.findIndex((wishlistBook) => wishlistBook._id === book._id) !==
            -1 ||
            addingWishlist.findIndex(
                (wishlistBook) => wishlistBook._id === book._id
            ) !== -1) &&
        !removedWishlist.includes(book._id);

    const inCart =
        (cart.findIndex((cartBook) => cartBook._id === book._id) !== -1 ||
            addingCart.findIndex((cartBook) => cartBook._id === book._id) !==
                -1) &&
        !removedCart.includes(book._id);

    return (
        <div className="book">
            <div className="book__header">
                <img src={book.ThumbnailPath} alt="" className="book__img" />
                <div className="book__actions">
                    <button
                        className="book__action"
                        title="Cart"
                        onClick={
                            inCart
                                ? (e) => removeFromCart(e, book)
                                : (e) => addToCart(e, book)
                        }
                    >
                        <IonIcon
                            icon={inCart ? cartIcon : cartOutline}
                            className={`book__action-icon ${
                                inCart ? "text-tertiary" : ""
                            }`}
                        />
                    </button>
                    <button
                        className="book__action"
                        title="Wishlist"
                        onClick={
                            inWishlist
                                ? (e) => removeFromWishlist(e, book)
                                : (e) => addToWishlist(e, book)
                        }
                    >
                        <IonIcon
                            icon={inWishlist ? heart : heartOutline}
                            className={`book__action-icon ${
                                inWishlist ? "text-tertiary" : ""
                            }`}
                        />
                    </button>
                    {book.AmazonLink ? (
                        <a
                            href={book.AmazonLink}
                            rel="noreferrer"
                            target="_blank"
                            className="book__action"
                            title="View on Amazon"
                        >
                            <IonIcon
                                icon={logoAmazon}
                                className="book__action-icon"
                            />
                        </a>
                    ) : null}
                </div>
            </div>
            <div className="book__main">
                <h4
                    className="book__name"
                    onClick={() => navigate(`/book/${book._id}`)}
                >
                    {book.Name}
                </h4>
                {/* <div className="book__rating">
                    <p className="book__rating-stars">
                        <IonIcon
                            icon={star}
                            className="book__rating-star book__rating-star--active"
                        />
                        <IonIcon
                            icon={star}
                            className="book__rating-star book__rating-star--active"
                        />
                        <IonIcon
                            icon={star}
                            className="book__rating-star book__rating-star--active"
                        />
                        <IonIcon
                            icon={star}
                            className="book__rating-star book__rating-star--active"
                        />
                        <IonIcon
                            icon={starOutline}
                            className="book__rating-star"
                        />
                    </p>
                    <p>4</p>
                </div> */}
                <p className="book__author">{book.Authors[0].PenName}</p>
                <div className="book__price">
                    <p>${book.Price.toFixed(2)}</p>
                    <small>${(book.Price / 0.7).toFixed(2)}</small>
                </div>
            </div>
        </div>
    );
};

export default BookCard;
