import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import api_client from "../../api/client";

import { updateCategories } from "../../store/cacheSlice";
import { tCategories } from "../../store/types/app.types";

import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";
import { tRootState } from "../../store";
import {
    updateCart,
    updatePurchases,
    updateWishlist,
} from "../../store/userSlice";

const useData = () => {
    const dispatch = useDispatch();

    const { accessToken, userDetails, wishlist, cart } = useSelector(
        (state: tRootState) => state.user
    );

    const loggedIn = accessToken && userDetails;

    const loadCart = useCallback(
        (accessToken: string) => {
            return new Promise((resolve, reject) => {
                api_client({
                    url: "/cart",
                    method: "POST",
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: cart.map((crt) => crt._id),
                })
                    .then((res) => {
                        return api_client({
                            url: "/cart",
                            headers: { Authorization: `Bearer ${accessToken}` },
                        });
                    })
                    .then((res) => {
                        resolve(res.data.data);
                    })
                    .catch((err) => {
                        reject("An error occurred. Login to your account");
                    });
            });
        },
        [cart]
    );

    const loadWishlist = useCallback(
        (accessToken: string) => {
            return new Promise((resolve, reject) => {
                api_client({
                    url: "/wishlist",
                    method: "POST",
                    headers: { Authorization: `Bearer ${accessToken}` },
                    data: wishlist.map((wishL) => wishL._id),
                })
                    .then((res) => {
                        return api_client({
                            url: "/wishlist",
                            headers: { Authorization: `Bearer ${accessToken}` },
                        });
                    })
                    .then((res) => {
                        resolve(res.data.data);
                    })
                    .catch((err) => {
                        reject("An error occurred. Login to your account");
                    });
            });
        },
        [wishlist]
    );

    const fetchCart = useCallback(() => {
        if (!loggedIn) return;

        return new Promise((resolve, reject) => {
            api_client({
                url: "/cart",
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    dispatch(updateCart(res.data.data));

                    resolve("Cart fetched successfully");
                })
                .catch((err) => {
                    // do nothing
                    resolve("Error fetching cart");
                });
        });
    }, [loggedIn, accessToken, dispatch]);

    const fetchWishlist = useCallback(() => {
        if (!loggedIn) return;

        return new Promise((resolve, reject) => {
            api_client({
                url: "/wishlist",
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    dispatch(updateWishlist(res.data.data));

                    resolve("Wishlist fetched successfully");
                })
                .catch((err) => {
                    // do nothing
                    resolve("Error fetching wishlist");
                });
        });
    }, [loggedIn, accessToken, dispatch]);

    const fetchPurchases = useCallback(() => {
        if (!loggedIn) return;

        return new Promise((resolve, reject) => {
            api_client({
                url: "/purchases",
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    dispatch(updatePurchases(res.data.data));

                    resolve("Purchases fetched successfully");
                })
                .catch((err) => {
                    // do nothing
                    resolve("Error fetching purchases");
                });
        });
    }, [loggedIn, accessToken, dispatch]);

    const fetchCategories = useCallback(() => {
        return new Promise((resolve, reject) => {
            api_client({
                url: `/categories?no_pagination=true`,
            })
                .then((res) => {
                    const categories: tCategories = res.data.data.categories;

                    const categoriesSelectData: SelectBoxDataType = [
                        { key: "all", value: { text: "All categories" } },
                    ];

                    for (const category of categories) {
                        categoriesSelectData.push({
                            key: category._id,
                            value: {
                                text: `${category.Name} (${category.NumBooks})`,
                            },
                        });
                    }

                    dispatch(
                        updateCategories({ categories, categoriesSelectData })
                    );

                    resolve("Categories fetched successfully");
                })
                .catch(() => {
                    resolve("Error fetching categories");
                });
        });
    }, [dispatch]);

    return {
        fetchCategories,
        fetchCart,
        fetchWishlist,
        fetchPurchases,
        loadCart,
        loadWishlist,
    };
};

export default useData;
