import { useNavigate } from "react-router-dom";

import Page from "../../layouts/Page/Page";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Page>
            <div className="container not-found">
                <h3 className="not-found__404">404</h3>
                <p className="not-found__heading">Oops!! Page Not Found</p>
                <p className="not-found__description">
                    The page you are looking for doesn't seem to exist.
                </p>
                <button
                    className="not-found__button"
                    onClick={() => navigate("/")}
                >
                    Go to Home
                </button>
            </div>
        </Page>
    );
};

export default NotFound;
