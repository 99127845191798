import { Fragment } from "react";

import BookCard from "../BookCard/BookCard";
import { tBooks } from "../../store/types/app.types";

import booksImg from "../../assets/images/books.jpg";

const SimilarBooks = ({ books }: { books: tBooks }) => {
    if (!books.length) return null;

    return (
        <section className="books-section section-separate">
            <div className="container">
                <div className="books-section__h-main">
                    <h1 className="heading-primary">Similar Books</h1>
                </div>
                <div className="similar-books">
                    <div className="similar-books__left">
                        <img src={booksImg} alt="" />
                        <h3 className="similar-books__left-heading">30% Off</h3>
                        <div className="similar-books__left-contact">
                            <p>Get Questions?</p>
                            <p>+2349138799855</p>
                        </div>
                    </div>
                    <div className="similar-books__right">
                        {books.map((book) => (
                            <Fragment key={book._id}>
                                <BookCard book={book} />
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SimilarBooks;
