import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import Page from "../../layouts/Page/Page";

import withAuth from "../../hoc/withAuth/withAuth";

import { tRootState } from "../../store";

import useData from "../../hooks/useData/useData";
import PurchasedBook from "../../components/PurchasedBook/PurchasedBook";

const Purchases = () => {
    const { purchases } = useSelector((state: tRootState) => state.user);

    const { fetchPurchases } = useData();

    useEffect(() => {
        fetchPurchases();
    }, [fetchPurchases]);

    return (
        <Page>
            <div className="wishlist container">
                <h3 className="wishlist__heading">Purchases</h3>
                {!purchases.length ? (
                    <div className="wishlist__main">
                        <div
                            className="cart-products__header"
                            style={{ justifyContent: "center" }}
                        >
                            <h3 className="cart-products__heading">
                                You have not purchased any book
                            </h3>
                        </div>
                    </div>
                ) : null}
                {purchases.length ? (
                    <div className="wishlist__main">
                        <div className="cart-products__header">
                            <h3 className="cart-products__heading">
                                All ({purchases.length} Items)
                            </h3>
                        </div>
                        <div className="cart-products">
                            {purchases.map((purchasedBook) => {
                                return (
                                    <Fragment key={purchasedBook._id}>
                                        <PurchasedBook
                                            purchasedBook={purchasedBook}
                                        />
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        </Page>
    );
};

export default withAuth(Purchases);
