import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api_client from "../../api/client";

import Page from "../../layouts/Page/Page";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IonIcon } from "@ionic/react";
import { arrowForwardOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";
import { login, updateCart, updateWishlist } from "../../store/userSlice";
import useAlert from "../../hooks/useAlert/useAlert";
import { tBooks, tUser } from "../../store/types/app.types";
import useData from "../../hooks/useData/useData";
import { withUnAuth } from "../../hoc/withAuth/withAuth";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const redir = useSearchParams()[0].get("redir");

    const { loadCart, loadWishlist } = useData();

    const [auth, setAuth] = useState("");
    const [password, setPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

    const [message, setMessage, clearMessage] = useAlert();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!auth || !password)
            return setMessage("warning", "Fill in all fields");

        const submitBtn = submitBtnRef.current;
        const currentHTML = submitBtn.innerHTML;

        submitBtn.setAttribute("disabled", "disabled");
        submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Logging In`;

        let accessToken: string;
        let userDetails: tUser;
        let cart: tBooks;

        api_client({
            url: "/users/login",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: { Auth: auth, Password: password },
        })
            .then((res) => {
                accessToken = res.data.data.accessToken;
                userDetails = res.data.data.user;

                return loadCart(accessToken);
            })
            .then((rCart) => {
                cart = rCart as tBooks;

                return loadWishlist(accessToken);
            })
            .then((wishlist) => {
                dispatch(login({ accessToken, userDetails }));
                dispatch(updateCart(cart));
                dispatch(updateWishlist(wishlist as tBooks));

                navigate(redir ? `/${redir}` : "/");
            })
            .catch((err) => {
                if (err.code === "ERR_BAD_REQUEST") {
                    setMessage("warning", err.response.data.message);
                } else {
                    setMessage("error", err.message);
                }
            })
            .finally(() => {
                if (!submitBtn) return;
                submitBtn.innerHTML = currentHTML;
                submitBtn.removeAttribute("disabled");
            });
    };

    useEffect(() => {
        clearMessage();
    }, [auth, password, clearMessage]);

    return (
        <Page>
            <div className="auth">
                <div className="auth__header">
                    <h1 className="auth__heading">Login</h1>
                    <p className="auth__sub-heading">Login to your account</p>
                </div>
                <form className="auth__form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="auth-field" className="form-label">
                            Username / Email
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="Enter your username / email"
                            id="auth-field"
                            value={auth}
                            onChange={(e) => setAuth(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password-field" className="form-label">
                            Password
                        </label>
                        <div className="form-input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="form-input"
                                placeholder="Enter your password"
                                id="password-field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword((sp) => !sp)}
                            >
                                <IonIcon
                                    icon={
                                        showPassword
                                            ? eyeOffOutline
                                            : eyeOutline
                                    }
                                />
                            </button>
                        </div>
                    </div>
                    <div className="auth__form-footer">
                        {message}
                        <button
                            className="button button--sm"
                            ref={submitBtnRef}
                            disabled={!auth || !password}
                        >
                            Login
                            <IonIcon icon={arrowForwardOutline} />
                        </button>
                        <div className="text-center">
                            Don't have an account?{" "}
                            <Link
                                to={`/sign-up${redir ? `?redir=${redir}` : ""}`}
                            >
                                Sign Up
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </Page>
    );
};

export default withUnAuth(Login);
