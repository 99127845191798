import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

import { saveState } from "./global";

type AddNotificationType = {
    type: "success" | "error" | "warning";
    heading?: string;
    text: string;
    autoClose?: boolean;
};

export type NotificationType = {
    id: number;
} & AddNotificationType;

type NotificationsType = {
    notifications: NotificationType[];
};

const NotificationsInitialState: NotificationsType = {
    notifications: [],
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState: NotificationsInitialState,
    reducers: {
        addNotification(
            state,
            {
                payload,
            }: PayloadAction<{ notification: AddNotificationType; id?: number }>
        ) {
            const notification: NotificationType = {
                ...payload.notification,
                id: payload.id ? payload.id : new Date().getTime(),
            };

            state.notifications = [...state.notifications, notification];

            saveState<NotificationsType>("notifications", current(state));
        },
        removeNotification(state, { payload: id }: PayloadAction<number>) {
            state.notifications = state.notifications.filter(
                (not) => not.id !== id
            );

            saveState<NotificationsType>("notifications", current(state));
        },
    },
});

export const { addNotification, removeNotification } =
    notificationsSlice.actions;

export default notificationsSlice.reducer;
