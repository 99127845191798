import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { heart, heartOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tFullBook } from "../../store/types/app.types";

import Page from "../../layouts/Page/Page";

import SimilarBooks from "../../components/SimilarBooks/SimilarBooks";
import Preloader from "../../components/Preloader/Preloader";

import { getDateTime } from "../../utils/func";
import useFunctions from "../../hooks/useFunctions/useFunctions";

const Book = () => {
    const navigate = useNavigate();

    const { id: bookId } = useParams();

    const accessToken = useSelector(
        (state: tRootState) => state.user.accessToken
    );

    const {
        cart,
        addingCart,
        removedCart,
        wishlist,
        addingWishlist,
        removedWishlist,
    } = useSelector((state: tRootState) => state.user);

    const { addToCart, removeFromCart, addToWishlist, removeFromWishlist } =
        useFunctions();

    const [book, setBook] = useState<tFullBook | null>(null);

    const [tab, setTab] = useState<"desc" | "specs" | "author">("desc");

    useEffect(() => {
        api_client({
            url: `/books/${bookId}?similarBooks=true`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                setBook(res.data.data);
            })
            .catch((err) => {
                navigate("/not_found");
            });
    }, [bookId, navigate, accessToken]);

    if (!book) return <Preloader />;

    const inWishlist =
        (wishlist.findIndex((wishlistBook) => wishlistBook._id === book._id) !==
            -1 ||
            addingWishlist.findIndex(
                (wishlistBook) => wishlistBook._id === book._id
            ) !== -1) &&
        !removedWishlist.includes(book._id);

    const inCart =
        (cart.findIndex((cartBook) => cartBook._id === book._id) !== -1 ||
            addingCart.findIndex((cartBook) => cartBook._id === book._id) !==
                -1) &&
        !removedCart.includes(book._id);

    return (
        <Page>
            <div className="book-details-block container">
                <div className="book-details">
                    <div className="book-details__img-block">
                        <img
                            src={book.ThumbnailPath}
                            alt=""
                            className="book-details__img"
                        />
                    </div>
                    <div className="book-details__main">
                        <h3 className="book-details__name">{book.Name}</h3>
                        <p className="book-details__author">
                            {book.Authors[0].PenName} (Author)
                        </p>
                        {/* <div className="book-details__review">
                                <div className="book-details__stars">
                                    <IonIcon icon={star} className="active" />
                                    <IonIcon icon={star} className="active" />
                                    <IonIcon icon={star} className="active" />
                                    <IonIcon icon={star} className="active" />
                                    <IonIcon icon={starOutline} />
                                </div>
                                <span className="text-primary">5 ratings</span>
                                <span>520 reviews</span>
                            </div> */}
                        <p className="book-details__description">
                            {book.Description}
                        </p>
                        <div className="book-details__price">
                            <p className="book-details__price-main">
                                <span>Market Price:</span>
                                <span>${book.Price.toFixed(2)}</span>
                            </p>
                            <span className="book-details__price-del">
                                ${(book.Price / 0.7).toFixed(2)}
                            </span>
                        </div>
                        <button
                            className="book-details__wishlist-btn"
                            onClick={
                                inWishlist
                                    ? (e) => removeFromWishlist(e, book)
                                    : (e) => addToWishlist(e, book)
                            }
                        >
                            <IonIcon icon={inWishlist ? heart : heartOutline} />
                            {inWishlist
                                ? "Remove from Wishlist"
                                : "Add to Wishlist"}
                        </button>
                        <div className="book-details__main-footer">
                            <div className="book-details__actions">
                                <button
                                    className={`button-secondary ${
                                        inCart ? "button-secondary--active" : ""
                                    }`}
                                    onClick={
                                        inCart
                                            ? (e) => removeFromCart(e, book)
                                            : (e) => addToCart(e, book)
                                    }
                                >
                                    {inCart
                                        ? "Remove from Cart"
                                        : "Add to Cart"}
                                </button>
                                {book.AmazonLink ? (
                                    <a
                                        href={book.AmazonLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="button-primary"
                                    >
                                        Buy on Amazon
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="book-tab">
                    <div className="book-tab__header">
                        <button
                            className={tab === "desc" ? "active" : ""}
                            onClick={() => setTab("desc")}
                        >
                            Description
                        </button>
                        <button
                            className={tab === "specs" ? "active" : ""}
                            onClick={() => setTab("specs")}
                        >
                            Specs
                        </button>
                        <button
                            className={tab === "author" ? "active" : ""}
                            onClick={() => setTab("author")}
                        >
                            Author
                        </button>
                    </div>
                    <div className="book-tab__body">
                        {["desc", "specs"].includes(tab) ? (
                            <div className="book-tab__block">
                                <h3 className="book-tab__heading">
                                    Book Details
                                </h3>
                                <ul className="book-tab__list">
                                    <li>
                                        <span>Published</span> :
                                        <span>
                                            {
                                                getDateTime(
                                                    book.DatePublished,
                                                    true
                                                )[0]
                                            }
                                        </span>
                                    </li>
                                    <li>
                                        <span>Language</span> :
                                        <span>{book.Language}</span>
                                    </li>
                                    <li>
                                        <span>Pages</span> :
                                        <span>{book.Pages}</span>
                                    </li>
                                </ul>
                            </div>
                        ) : null}
                        {["desc", "author"].includes(tab) ? (
                            <div className="book-tab__block">
                                <h3 className="book-tab__heading">
                                    ABOUT THE AUTHOR
                                </h3>
                                <p className="book-tab__text">
                                    {book.Authors[0].Description}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
                <SimilarBooks books={book.SimilarBooks} />
            </div>
        </Page>
    );
};

export default Book;
