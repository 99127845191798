import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api_client from "../../api/client";

import Page from "../../layouts/Page/Page";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IonIcon } from "@ionic/react";
import { arrowForwardOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";
import { login, updateCart, updateWishlist } from "../../store/userSlice";
import useAlert from "../../hooks/useAlert/useAlert";
import { tBooks, tUser } from "../../store/types/app.types";
import useData from "../../hooks/useData/useData";
import validator from "validator";
import { withUnAuth } from "../../hoc/withAuth/withAuth";

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const redir = useSearchParams()[0].get("redir");

    const { loadCart, loadWishlist } = useData();

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);

    const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

    const [message, setMessage, clearMessage] = useAlert();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!name || !username || !email || !password)
            return setMessage("warning", "Fill in all fields");

        if (!validator.isEmail(email))
            return setMessage("warning", "Enter a valid email address");

        const submitBtn = submitBtnRef.current;
        const currentHTML = submitBtn.innerHTML;

        submitBtn.setAttribute("disabled", "disabled");
        submitBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Signing Up`;

        let accessToken: string;
        let userDetails: tUser;
        let cart: tBooks;

        api_client({
            url: "/users/sign-up",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: {
                FullName: name,
                EmailAddress: email,
                Username: username,
                Password: password,
            },
        })
            .then((res) => {
                accessToken = res.data.data.accessToken;
                userDetails = res.data.data.user;

                return loadCart(accessToken);
            })
            .then((rCart) => {
                cart = rCart as tBooks;

                return loadWishlist(accessToken);
            })
            .then((wishlist) => {
                dispatch(login({ accessToken, userDetails }));
                dispatch(updateCart(cart));
                dispatch(updateWishlist(wishlist as tBooks));

                navigate(redir ? `/${redir}` : "/");
            })
            .catch((err) => {
                if (err.code === "ERR_BAD_REQUEST") {
                    setMessage("warning", err.response.data.message);
                } else {
                    setMessage("error", err.message);
                }
            })
            .finally(() => {
                if (!submitBtn) return;
                submitBtn.innerHTML = currentHTML;
                submitBtn.removeAttribute("disabled");
            });
    };

    useEffect(() => {
        clearMessage();
    }, [name, username, email, password, clearMessage]);

    return (
        <Page>
            <div className="auth">
                <div className="auth__header">
                    <h1 className="auth__heading">Sign Up</h1>
                    <p className="auth__sub-heading">Create an account</p>
                </div>
                <form className="auth__form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name-field" className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="Enter your name"
                            id="name-field"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username-field" className="form-label">
                            Username
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="Create a username"
                            id="username-field"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email-field" className="form-label">
                            Email
                        </label>
                        <input
                            type="email"
                            className="form-input"
                            placeholder="Enter your email address"
                            id="email-field"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password-field" className="form-label">
                            Password
                        </label>
                        <div className="form-input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="form-input"
                                placeholder="Enter your password"
                                id="password-field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword((sp) => !sp)}
                            >
                                <IonIcon
                                    icon={
                                        showPassword
                                            ? eyeOffOutline
                                            : eyeOutline
                                    }
                                />
                            </button>
                        </div>
                    </div>
                    <div className="auth__form-footer">
                        {message}
                        <button
                            className="button button--sm"
                            ref={submitBtnRef}
                            disabled={
                                !name ||
                                !username ||
                                !email ||
                                !validator.isEmail(email) ||
                                !password
                            }
                        >
                            Sign Up
                            <IonIcon icon={arrowForwardOutline} />
                        </button>
                        <div className="text-center">
                            Already have an account?{" "}
                            <Link
                                to={`/login${redir ? `?redir=${redir}` : ""}`}
                            >
                                Login
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </Page>
    );
};

export default withUnAuth(SignUp);
