import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";
import { tUser, tPurchasedBooks, tBooks, tBook } from "./types/app.types";

type UserType = {
    accessToken: string;
    userDetails: tUser | null;
    wishlist: tBooks;
    removedWishlist: string[];
    addingWishlist: tBooks;
    cart: tBooks;
    removedCart: string[];
    addingCart: tBooks;
    purchases: tPurchasedBooks;
};

const UserInitialState: UserType = {
    accessToken: "",
    userDetails: null,
    wishlist: [],
    removedWishlist: [],
    addingWishlist: [],
    cart: [],
    removedCart: [],
    addingCart: [],
    purchases: [],
};

const userSlice = createSlice({
    name: "user",
    initialState: getState<UserType>("user", UserInitialState),
    reducers: {
        login(
            state,
            {
                payload,
            }: PayloadAction<{
                accessToken: string;
                userDetails: tUser;
            }>
        ) {
            state.accessToken = payload.accessToken;
            state.userDetails = payload.userDetails;

            saveState<UserType>("user", current(state));
        },

        updateWishlist(state, { payload }: PayloadAction<tBooks>) {
            state.wishlist = payload;

            saveState<UserType>("user", current(state));
        },
        addProductToWishlist(state, { payload }: PayloadAction<tBook>) {
            if (
                state.wishlist.findIndex((book) => book._id === payload._id) !==
                -1
            )
                return;

            state.wishlist = [...state.wishlist, payload];

            saveState<UserType>("user", current(state));
        },
        removeProductFromWishlist(state, { payload }: PayloadAction<string>) {
            state.wishlist = state.wishlist.filter(
                (book) => book._id !== payload
            );

            saveState<UserType>("user", current(state));
        },
        removeWishlistProduct(state, { payload }: PayloadAction<string>) {
            if (state.removedWishlist.includes(payload)) return;

            state.removedWishlist = [...state.removedWishlist, payload];

            saveState<UserType>("user", current(state));
        },
        undoRemoveWishlistProduct(state, { payload }: PayloadAction<string>) {
            state.removedWishlist = state.removedWishlist.filter(
                (prod) => prod !== payload
            );

            saveState<UserType>("user", current(state));
        },
        addWishlistProduct(state, { payload }: PayloadAction<tBook>) {
            if (
                state.addingWishlist.findIndex(
                    (book) => book._id === payload._id
                ) !== -1
            )
                return;

            state.addingWishlist = [...state.addingWishlist, payload];

            saveState<UserType>("user", current(state));
        },
        undoAddWishlistProduct(state, { payload }: PayloadAction<string>) {
            state.addingWishlist = state.addingWishlist.filter(
                (book) => book._id !== payload
            );

            saveState<UserType>("user", current(state));
        },

        updateCart(state, { payload }: PayloadAction<tBooks>) {
            state.cart = payload;

            saveState<UserType>("user", current(state));
        },
        addProductToCart(state, { payload }: PayloadAction<tBook>) {
            if (state.cart.findIndex((book) => book._id === payload._id) !== -1)
                return;

            state.cart = [...state.cart, payload];

            saveState<UserType>("user", current(state));
        },
        removeProductFromCart(state, { payload }: PayloadAction<string>) {
            state.cart = state.cart.filter((book) => book._id !== payload);

            saveState<UserType>("user", current(state));
        },
        removeCartProduct(state, { payload }: PayloadAction<string>) {
            if (state.removedCart.includes(payload)) return;

            state.removedCart = [...state.removedCart, payload];

            saveState<UserType>("user", current(state));
        },
        undoRemoveCartProduct(state, { payload }: PayloadAction<string>) {
            state.removedCart = state.removedCart.filter(
                (prod) => prod !== payload
            );

            saveState<UserType>("user", current(state));
        },
        updatePurchases(state, { payload }: PayloadAction<tPurchasedBooks>) {
            state.purchases = payload;

            saveState<UserType>("user", current(state));
        },
        addCartProduct(state, { payload }: PayloadAction<tBook>) {
            if (
                state.addingCart.findIndex(
                    (book) => book._id === payload._id
                ) !== -1
            )
                return;

            state.addingCart = [...state.addingCart, payload];

            saveState<UserType>("user", current(state));
        },
        undoAddCartProduct(state, { payload }: PayloadAction<string>) {
            state.addingCart = state.addingCart.filter(
                (book) => book._id !== payload
            );

            saveState<UserType>("user", current(state));
        },
    },
});

export const {
    login,

    updateCart,
    addProductToCart,
    removeProductFromCart,
    removeCartProduct,
    undoRemoveCartProduct,
    addCartProduct,
    undoAddCartProduct,

    updateWishlist,
    addProductToWishlist,
    removeProductFromWishlist,
    removeWishlistProduct,
    undoRemoveWishlistProduct,
    addWishlistProduct,
    undoAddWishlistProduct,

    updatePurchases,
} = userSlice.actions;

export default userSlice.reducer;
