import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavigateCleanup from "./hoc/NavigateCleanup/NavigateCleanup";

import NotFound from "./pages/NotFound/NotFound";

import Home from "./pages/Home/Home";

import Books from "./pages/Books/Books";
import Book from "./pages/Book/Book";

import Cart from "./pages/Cart/Cart";
import Wishlist from "./pages/Wishlist/Wishlist";
import Purchases from "./pages/Purchases/Purchases";

import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Logout from "./pages/Logout/Logout";

function App() {
    return (
        <BrowserRouter>
            <NavigateCleanup />
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/books" element={<Books />} />
                <Route path="/book/:id" element={<Book />} />

                <Route path="/cart" element={<Cart />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/purchases" element={<Purchases />} />

                <Route path="/login" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/logout" element={<Logout />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
