import axios from "axios";
import { BASE_URL } from "../data";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("appState");
            window.location.reload();
            return;
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
