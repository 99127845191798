import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

import Categories from "../../components/Categories/Categories";
import FeaturedBooks from "../../components/FeaturedBooks/FeaturedBooks";
import TopBooks from "../../components/TopBooks/TopBooks";

import booksStacked from "../../assets/images/book-stacked.png";
import Page from "../../layouts/Page/Page";

const Home = () => {
    return (
        <Page>
            <section className="hero section-separate">
                <div className="hero__container container">
                    <div className="hero__main">
                        <p className="hero__label u-text-uppercase">
                            Our Biggest Sale
                        </p>
                        <h2 className="hero__heading">Buy Quality Books</h2>
                        <p className="hero__label">
                            Available 24/7 for purchase. No down time <br />
                            Books are in PDF format and are downloadable after
                            purchase
                        </p>
                        <button className="hero__btn">
                            <span>Shop Now</span>
                            <IonIcon icon={chevronForwardOutline} />
                        </button>
                    </div>
                </div>
            </section>
            <Categories />
            <TopBooks />
            <FeaturedBooks />
            <section className="newsletter section-separate">
                <div className="newsletter__container container">
                    <div className="newsletter__main">
                        <h1 className="newsletter__heading">
                            Subscribe to our newsletter
                        </h1>
                        <p className="newsletter__sub-heading">
                            Subscribe to our newsletter to stay updated on new
                            books published
                        </p>
                        <div className="newsletter__search-form">
                            <input
                                type="text"
                                className="newsletter__search-input"
                                placeholder="Your email address"
                            />
                            <button className="newsletter__button">
                                Subscribe
                            </button>
                        </div>
                    </div>
                    <img
                        src={booksStacked}
                        alt=""
                        className="newsletter__img"
                    />
                </div>
            </section>
        </Page>
    );
};

export default Home;
