import { Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";

import { tRootState } from "../../store";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import Notification from "../../components/Notification/Notification";

const Page = ({ children }: { children: ReactNode }) => {
    const { notifications } = useSelector(
        (state: tRootState) => state.notifications
    );

    return (
        <>
            <Header />
            <main className="main">{children}</main>
            <Footer />
            <div className="pgn">
                {notifications.map((notification) => {
                    return (
                        <Fragment key={notification.id}>
                            <Notification {...notification} />
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default Page;
