import { FormEvent, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updatePurchases } from "../../store/userSlice";

import { updateCart } from "../../store/userSlice";
import {
    addNotification,
    removeNotification,
} from "../../store/notificationsSlice";

import { PAYSTACK_PUBLIC_KEY } from "../../data";

import Page from "../../layouts/Page/Page";
import CartBook from "../../components/CartBook/CartBook";
import useData from "../../hooks/useData/useData";
import { sleep } from "../../utils/func";

declare global {
    interface Window {
        PaystackPop: any;
    }
}

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { accessToken, userDetails, cart, removedCart, addingCart } =
        useSelector((state: tRootState) => state.user);

    const loggedIn = accessToken && userDetails;

    const { fetchCart } = useData();

    const validateCartPurchase = async (reference: string) => {
        const notificationId = new Date().getTime();

        dispatch(
            addNotification({
                notification: {
                    type: "warning",
                    text: `<span class="fas fa-spinner fa-spin"></span> Validating purchase`,
                    autoClose: false,
                },
                id: notificationId,
            })
        );

        let success = false;

        const currentDate = Date.now();

        while (Date.now() < currentDate + 60000 && !success) {
            try {
                const response = await api_client({
                    url: `/cart/checkout/validate/${reference}`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.data.data.status === "VERIFIED") {
                    const purchases = await api_client({
                        url: `/purchases`,
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    dispatch(updatePurchases(purchases.data.data));

                    success = true;

                    break;
                }

                await sleep(5000);
            } catch (error) {
                continue;
            }
        }

        dispatch(removeNotification(notificationId));

        if (!success) {
            dispatch(
                addNotification({
                    notification: {
                        type: "warning",
                        text: "Error validating purchase",
                    },
                })
            );
        } else {
            dispatch(
                addNotification({
                    notification: {
                        type: "success",
                        text: "Purchase validated successfully",
                    },
                })
            );

            dispatch(updateCart([]));
            navigate("/purchases");
        }
    };

    const initiatePaystack = (transaction: {
        Cart: string;
        PaystackRefID: string;
        Amount: number;
    }) => {
        let handler = window.PaystackPop.setup({
            key: PAYSTACK_PUBLIC_KEY,
            email: userDetails?.EmailAddress,
            amount: transaction.Amount * 100,
            currency: "USD",
            ref: transaction.PaystackRefID,
            metadata: {
                CartID: transaction.Cart,
            },
            onClose: function () {
                dispatch(
                    addNotification({
                        notification: {
                            type: "warning",
                            text: "Purchase closed",
                        },
                    })
                );
            },
            callback: function (response: { reference: string }) {
                validateCartPurchase(response.reference);
            },
        });
        handler.openIframe();
    };

    const initiateCartPurchase = (e: FormEvent<HTMLButtonElement>) => {
        if (!loggedIn) return navigate("/login?redir=cart");

        const triggerBtn = e.target as HTMLButtonElement;

        const btnHtml = triggerBtn.innerHTML;

        triggerBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
        triggerBtn.setAttribute("disabled", "disabled");

        api_client({
            url: "/cart/checkout/initiate",
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                initiatePaystack(res.data.data);
            })
            .catch((err) => {
                dispatch(
                    addNotification({
                        notification: {
                            type:
                                err.code === "ERR_BAD_REQUEST"
                                    ? "warning"
                                    : "error",
                            text:
                                err.code === "ERR_BAD_REQUEST"
                                    ? err.response.data.message
                                    : err.message,
                        },
                    })
                );
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
                triggerBtn.innerHTML = btnHtml;
            });
    };

    useEffect(() => {
        fetchCart();
    }, [fetchCart]);

    const displayCart = [
        ...cart.filter((book) => !removedCart.includes(book._id)),
        ...addingCart.filter(
            (book) => cart.findIndex((bk) => bk._id === book._id) === -1
        ),
    ];

    const total: number = displayCart.reduce((ttl, bk) => ttl + bk.Price, 0);

    return (
        <Page>
            <div className="cart-block container">
                <h3 className="cart-block__heading">Cart</h3>
                {!displayCart.length ? (
                    <div className="wishlist__main">
                        <div
                            className="cart-products__header"
                            style={{ justifyContent: "center" }}
                        >
                            <h3 className="cart-products__heading">
                                No Book in Cart
                            </h3>
                        </div>
                    </div>
                ) : null}
                {displayCart.length ? (
                    <div className="cart-block__main">
                        <div className="cart-block__main-left">
                            <div className="cart-products__header">
                                <h3 className="cart-products__heading">
                                    All ({displayCart.length} Items)
                                </h3>
                                <p className="cart-products__header-right">
                                    Total:
                                    <span>${total.toFixed(2)}</span>
                                </p>
                            </div>
                            <div className="cart-products">
                                {displayCart.map((book) => {
                                    return (
                                        <Fragment key={book._id}>
                                            <CartBook book={book} />
                                        </Fragment>
                                    );
                                })}
                            </div>
                            <div className="text-right u-mt-small">
                                <button
                                    className="button py-small"
                                    onClick={initiateCartPurchase}
                                >
                                    Buy Now
                                    <IonIcon icon={chevronForwardOutline} />
                                </button>
                            </div>
                        </div>
                        <div className="cart-block__main-right">
                            <div className="cart-block__sidebar">
                                <div className="cart-block__sidebar-main">
                                    <h3 className="checkout-block__heading">
                                        Checkout Summary
                                    </h3>
                                    <div className="checkout-block__info">
                                        <p>Subtotal</p>
                                        <p>{total.toFixed(2)} USD</p>
                                    </div>
                                    <div className="checkout-block__info">
                                        <p>Shipping</p>
                                        <p>0 USD</p>
                                    </div>
                                    <div className="checkout-block__info">
                                        <p>Payable Total</p>
                                        <p>{total.toFixed(2)} USD</p>
                                    </div>
                                </div>
                                <div className="cart-block__sidebar-footer">
                                    <button
                                        className="button w-100"
                                        onClick={initiateCartPurchase}
                                    >
                                        Checkout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </Page>
    );
};

export default Cart;
