import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

import { tRootState } from "../../store";

import useData from "../../hooks/useData/useData";
import htmr from "htmr";

const Categories = () => {
    const { categories } = useSelector((state: tRootState) => state.cache);

    const { fetchCategories } = useData();

    const cats = [...categories];
    cats.sort((cat1, cat2) => cat2.NumBooks - cat1.NumBooks);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    if (!categories.length) return null;

    return (
        <section className="categories-section section-separate">
            <div className="container">
                <div className="categories-section__header">
                    <h1 className="heading-primary">Categories</h1>
                </div>
                <div className="categories">
                    {categories.map((cat, i) => {
                        return (
                            <div
                                className={`category category--${(i % 5) + 1}`}
                                key={cat._id}
                            >
                                <div
                                    className="category__icon-block"
                                    dangerouslySetInnerHTML={{
                                        __html: htmr(cat.Icon) as string,
                                    }}
                                ></div>
                                <p className="category__name">{cat.Name}</p>
                                <Link
                                    to={`/books?category=${cat._id}`}
                                    className="category__link"
                                >
                                    <span>Shop Now</span>
                                    <IonIcon icon={chevronForwardOutline} />
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Categories;
