import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./userSlice";
import cacheReducer from "./cacheSlice";
import notificationsReducer from "./notificationsSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        cache: cacheReducer,
        notifications: notificationsReducer,
    },
});

export type tRootState = ReturnType<typeof store.getState>;
