import { FormEvent, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { updateWishlist } from "../../store/userSlice";
import { addNotification } from "../../store/notificationsSlice";

import useData from "../../hooks/useData/useData";

import Page from "../../layouts/Page/Page";

import WishlistBook from "../../components/WishlistBook/WishlistBook";

const Wishlist = () => {
    const dispatch = useDispatch();

    const { accessToken, wishlist, addingWishlist, removedWishlist } =
        useSelector((state: tRootState) => state.user);

    const { fetchWishlist } = useData();

    // Come back to
    const removeAllBooks = (e: FormEvent<HTMLButtonElement>) => {
        const triggerBtn = e.target as HTMLButtonElement;

        const triggerBtnHTML = triggerBtn.innerHTML;

        triggerBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span> Removing all books`;
        triggerBtn.setAttribute("disbled", "disabled");

        api_client({
            url: `/wishlist`,
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                dispatch(
                    addNotification({
                        notification: {
                            type: "success",
                            text: res.data.message,
                        },
                    })
                );
                dispatch(updateWishlist(res.data.wishlist));
            })
            .catch((err) => {
                dispatch(
                    addNotification({
                        notification: {
                            type:
                                err.code === "ERR_BAD_REQUEST"
                                    ? "warning"
                                    : "error",
                            text:
                                err.code === "ERR_BAD_REQUEST"
                                    ? err.response.data.message
                                    : err.message,
                        },
                    })
                );
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
                triggerBtn.innerHTML = triggerBtnHTML;
            });
    };

    useEffect(() => {
        fetchWishlist();
    }, [fetchWishlist]);

    const displayWishlist = [
        ...wishlist.filter((book) => !removedWishlist.includes(book._id)),
        ...addingWishlist.filter(
            (book) => wishlist.findIndex((bk) => bk._id === book._id) === -1
        ),
    ];

    const total: number = displayWishlist.reduce(
        (ttl, bk) => ttl + bk.Price,
        0
    );

    return (
        <Page>
            <div className="wishlist container">
                <h3 className="wishlist__heading">Wishlist</h3>
                {!displayWishlist.length ? (
                    <div className="wishlist__main">
                        <div
                            className="cart-products__header"
                            style={{ justifyContent: "center" }}
                        >
                            <h3 className="cart-products__heading">
                                No Book in Wishlist
                            </h3>
                        </div>
                    </div>
                ) : null}
                {displayWishlist.length ? (
                    <div className="wishlist__main">
                        <div className="cart-products__header">
                            <h3 className="cart-products__heading">
                                All ({displayWishlist.length} Items)
                            </h3>
                            <p className="cart-products__header-right">
                                Total:
                                <span>${total.toFixed(2)}</span>
                            </p>
                        </div>
                        <div className="cart-products">
                            {displayWishlist.map((book) => {
                                return (
                                    <Fragment key={book._id}>
                                        <WishlistBook book={book} />
                                    </Fragment>
                                );
                            })}
                        </div>
                        <div className="text-right u-mt-small">
                            <button
                                className="button py-small"
                                onClick={removeAllBooks}
                            >
                                <IonIcon icon={closeOutline} />
                                Remove all
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </Page>
    );
};

export default Wishlist;
