import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import {
    alertCircle,
    checkmarkCircle,
    closeCircle,
    close,
} from "ionicons/icons";

import {
    NotificationType,
    removeNotification,
} from "../../store/notificationsSlice";

const Notification = ({
    id,
    type,
    heading,
    text,
    autoClose = true,
}: NotificationType) => {
    const dispatch = useDispatch();

    const remNotification = useCallback(() => {
        dispatch(removeNotification(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (autoClose) setTimeout(remNotification, 3000);
    }, [remNotification, autoClose]);

    return (
        <div className={`pgn__notification pgn__notification--${type}`}>
            {type === "success" ? <IonIcon icon={checkmarkCircle} /> : null}
            {type === "error" ? <IonIcon icon={closeCircle} /> : null}
            {type === "warning" ? <IonIcon icon={alertCircle} /> : null}
            <div className="pgn__main">
                {heading ? <p className="pgn__heading">Success</p> : null}
                <p
                    className="pgn__text"
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </div>
            <span className="pgn__close" onClick={remNotification}>
                <IonIcon icon={close} />
            </span>
        </div>
    );
};

export default Notification;
