import { MONTHS, MONTHS_SHORT } from "../data";

export const getSkip = (page: number, division: number) =>
    (page - 1) * division;

export const getDateTime = (date_string: string, short: boolean = false) => {
    const new_date = new Date(date_string);

    const day = new_date.getDate();
    const month = new_date.getMonth();
    const year = new_date.getFullYear();

    const hours = new_date.getHours();
    const meridian = hours < 12 ? "AM" : "PM";

    let hours12format = hours % 12;
    hours12format = hours12format ? hours12format : 12;

    const comp_date = `${day} ${
        short ? MONTHS_SHORT[month] : MONTHS[month]
    } ${year}`;
    const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
        new_date.getMinutes()
    ).padStart(2, "0")}:${String(new_date.getSeconds()).padStart(
        2,
        "0"
    )} ${meridian}`;

    return [comp_date, comp_time];
};

export const sleep = (milliseconds: number) => {
    return new Promise((resolve, reject) => {
        const interval = window.setInterval(() => {
            resolve("Woken Up");
            window.clearInterval(interval);
        }, milliseconds);
    });
};
