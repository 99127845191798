import { Link } from "react-router-dom";

import cards from "../../assets/images/cards.jpg";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__main"></div>
            <div className="copyright">
                <div className="copyright__main container">
                    <p className="copyright__text">
                        &copy; Designed by <Link to="#">Kawsar Ahmed</Link>,
                        Developed by <Link to="#">Alley</Link>. All rights
                        reserved
                    </p>
                    <img src={cards} alt="" className="copyright__img" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
