import { useState, ReactNode } from "react";

import SelectBox, {
    SelectBoxDataType,
} from "../../components/SelectBox/SelectBox";

const useSelectBox = (
    title: string,
    data: SelectBoxDataType | null,
    defaultValue?: string
): [ReactNode, string, () => void, () => void] => {
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>(defaultValue || "");

    const render =
        open && data ? (
            <SelectBox
                title={title}
                data={data}
                selected={selected}
                handler={(key) => {
                    setSelected(key);
                    setOpen(false);
                }}
            />
        ) : null;

    return [
        render,
        selected,
        () => {
            setOpen(true);
        },
        () => {
            setOpen(false);
        },
    ];
};

export default useSelectBox;
