import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tCategories } from "./types/app.types";

import { SelectBoxDataType } from "../components/SelectBox/SelectBox";

type CacheType = {
    categories: tCategories;
    categoriesSelectData: SelectBoxDataType;
};

const CacheInitialState: CacheType = {
    categories: [],
    categoriesSelectData: [],
};

const cacheSlice = createSlice({
    name: "cache",
    initialState: getState<CacheType>("cache", CacheInitialState),
    reducers: {
        updateCategories(
            state,
            {
                payload,
            }: PayloadAction<{
                categories: tCategories;
                categoriesSelectData: SelectBoxDataType;
            }>
        ) {
            state.categories = payload.categories;
            state.categoriesSelectData = payload.categoriesSelectData;

            saveState<CacheType>("cache", current(state));
        },
    },
});

export const { updateCategories } = cacheSlice.actions;

export default cacheSlice.reducer;
