import { FormEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
    logoFacebook,
    logoTwitter,
    logoInstagram,
    logoTiktok,
    logoLinkedin,
    heartOutline,
    cartOutline,
    personCircleOutline,
    gridOutline,
    chevronDownOutline,
    chevronUpOutline,
    searchOutline,
    bookOutline,
    mailOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tCategory } from "../../store/types/app.types";

import useData from "../../hooks/useData/useData";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";

import logo from "../../assets/images/logo.png";

const Header = () => {
    const navigate = useNavigate();

    const { categories, categoriesSelectData } = useSelector(
        (state: tRootState) => state.cache
    );
    const {
        accessToken,
        userDetails,

        cart,
        addingCart,
        removedCart,

        wishlist,
        addingWishlist,
        removedWishlist,

        purchases,
    } = useSelector((state: tRootState) => state.user);

    const { fetchCategories } = useData();

    const [search, setSearch] = useState("");

    const [categorySelectBox, selectedCategoryId, openSelectBox] = useSelectBox(
        "Select Category",
        categoriesSelectData,
        "all"
    );
    const [category, setCategory] = useState<tCategory | null>(null);

    const handleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!search) return;
        navigate(
            `/books?query=${search}${
                selectedCategoryId && selectedCategoryId !== "all"
                    ? `&category=${selectedCategoryId}`
                    : ""
            }`
        );
    };

    useEffect(() => {
        if (!selectedCategoryId) return;

        setCategory(
            categories.find((ct) => ct._id === selectedCategoryId) || null
        );
    }, [selectedCategoryId, categories]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    // menu categories
    const menuCats = categories.filter((cat) => cat.Top);
    menuCats.sort((cat1, cat2) => cat2.NumBooks - cat1.NumBooks);

    // categories
    const cats = [...categories];
    cats.sort((cat1, cat2) => cat2.NumBooks - cat1.NumBooks);

    // displayCart
    const displayCart = [
        ...cart.filter((book) => !removedCart.includes(book._id)),
        ...addingCart.filter(
            (book) => cart.findIndex((bk) => bk._id === book._id) === -1
        ),
    ];

    // displayWishlist
    const displayWishlist = [
        ...wishlist.filter((book) => !removedWishlist.includes(book._id)),
        ...addingWishlist.filter(
            (book) => wishlist.findIndex((bk) => bk._id === book._id) === -1
        ),
    ];

    // logged in
    const loggedIn = accessToken && userDetails;

    return (
        <>
            {categorySelectBox}
            <div className="h-top">
                <div className="h-top__main container">
                    <div className="h-top__phone">
                        <IonIcon icon={mailOutline} />
                        <p>omicab2020@gmail.com</p>
                    </div>
                    <div className="h-top__socials">
                        <a
                            href="https://www.facebook.com/omicab.infotech"
                            className="h-top__social"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IonIcon
                                icon={logoFacebook}
                                className="h-top__social-icon"
                            />
                        </a>
                        <a
                            href="https://twitter.com/micah_gbenga"
                            className="h-top__social"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IonIcon
                                icon={logoTwitter}
                                className="h-top__social-icon"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/micahdanielsgbenga/?igsh=bTNtZTlzNWF6aDI3"
                            className="h-top__social"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IonIcon
                                icon={logoInstagram}
                                className="h-top__social-icon"
                            />
                        </a>
                        <a
                            href="https://www.tiktok.com/@omicab_books"
                            className="h-top__social"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IonIcon
                                icon={logoTiktok}
                                className="h-top__social-icon"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com"
                            className="h-top__social"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IonIcon
                                icon={logoLinkedin}
                                className="h-top__social-icon"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="h-main">
                <div className="h-main__main container">
                    <div
                        className="h-main__brand"
                        onClick={() => navigate("/")}
                    >
                        <img src={logo} alt="" className="h-main__brand-img" />
                    </div>
                    <ul className="h-main__navbar">
                        <li className="h-main__navbar-item">
                            <Link
                                to="/"
                                className="h-main__navbar-link h-main__navbar-link--active"
                            >
                                Home
                            </Link>
                        </li>
                        {menuCats.map((cat, i) => {
                            return (
                                <li
                                    className="h-main__navbar-item"
                                    key={cat._id}
                                >
                                    <Link
                                        to={`/books?category=${cat._id}`}
                                        className="h-main__navbar-link"
                                    >
                                        {cat.Name}{" "}
                                        {i === 0 ? <label>Top</label> : null}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="h-main__right">
                        <Link to="/wishlist" className="h-main__icon-link">
                            <IonIcon icon={heartOutline} />
                            {displayWishlist.length ? (
                                <label>{displayWishlist.length}</label>
                            ) : null}
                        </Link>
                        <Link to="/cart" className="h-main__icon-link">
                            <IonIcon icon={cartOutline} />
                            {displayCart.length ? (
                                <label>{displayCart.length}</label>
                            ) : null}
                        </Link>
                        {loggedIn ? (
                            <Link to="/purchases" className="h-main__icon-link">
                                <IonIcon icon={bookOutline} />
                                {purchases.length ? (
                                    <label>{purchases.length}</label>
                                ) : null}
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="h-bottom">
                <div className="h-bottom__main container">
                    <div className="h-bottom__categories-block">
                        <button className="h-bottom__categories-h-main">
                            <p>
                                <IonIcon icon={gridOutline} />
                                Categories
                            </p>
                            <IonIcon icon={chevronDownOutline} />
                        </button>
                        <ul className="h-bottom__categories-body">
                            {cats.map((cat) => {
                                return (
                                    <li
                                        className="h-bottom__category"
                                        key={cat._id}
                                    >
                                        <Link to={`/books?category=${cat._id}`}>
                                            {cat.Name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <form
                        className="h-bottom__search-form"
                        onSubmit={handleSearch}
                    >
                        <input
                            type="text"
                            className="h-bottom__search-input"
                            placeholder="Search books"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div
                            className="h-bottom__search-cat-select"
                            onClick={openSelectBox}
                        >
                            <p>{category?.Name || "All categories"}</p>
                            <div className="h-bottom__search-cat-icons">
                                <IonIcon icon={chevronUpOutline} />
                                <IonIcon icon={chevronDownOutline} />
                            </div>
                        </div>
                        <button className="h-bottom__search-btn">
                            <IonIcon
                                icon={searchOutline}
                                className="h-bottom__search-icon"
                            />
                        </button>
                    </form>
                    <div className="h-bottom__categories-block">
                        <button className="h-bottom__categories-h-main h-bottom__categories-h-main--1">
                            <p>
                                <IonIcon
                                    icon={personCircleOutline}
                                    className="h-bottom__right-icon"
                                />
                                {loggedIn ? userDetails.Username : "User"}
                            </p>
                            <IonIcon icon={chevronDownOutline} />
                        </button>
                        <ul className="h-bottom__categories-body">
                            {loggedIn ? (
                                <li className="h-bottom__category">
                                    <Link to="/logout">Logout</Link>
                                </li>
                            ) : (
                                <>
                                    <li className="h-bottom__category">
                                        <Link to="/login">Login</Link>
                                    </li>
                                    <li className="h-bottom__category">
                                        <Link to="/sign-up">Sign Up</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    {/* <div className="h-bottom__right">
                        <IonIcon
                            icon={personCircleOutline}
                            className="h-bottom__right-icon"
                        />
                        <p
                            className="h-bottom__right-text"
                            style={{ textDecoration: "none" }}
                        >
                            Hello, {loggedIn ? userDetails.Username : "User"}
                        </p>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Header;
