import { IonIcon } from "@ionic/react";
import { tPurchasedBook } from "../../store/types/app.types";
import { downloadOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { tRootState } from "../../store";
import { FormEvent, useEffect, useState } from "react";
import { addNotification } from "../../store/notificationsSlice";
import useData from "../../hooks/useData/useData";
import api_client from "../../api/client";
import { useNavigate } from "react-router-dom";

const PurchasedBook = ({
    purchasedBook,
}: {
    purchasedBook: tPurchasedBook;
}) => {
    const book = purchasedBook.Book;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { accessToken } = useSelector((state: tRootState) => state.user);

    const { fetchPurchases } = useData();

    const [url, setUrl] = useState("");

    const downloadBook = (e: FormEvent<HTMLButtonElement>) => {
        const triggerBtn = (e.target! as HTMLElement).closest("button")!;

        const btnHtml = triggerBtn.innerHTML;

        triggerBtn.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
        triggerBtn.setAttribute("disabled", "disabled");

        api_client({
            url: `/purchases/download/${book._id}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            responseType: "arraybuffer",
        })
            .then((res) => {
                const blob = new Blob([res.data], {
                    type: "application/pdf",
                });

                setUrl(window.URL.createObjectURL(blob));

                return fetchPurchases();
            })
            .then((res) => {
                // all done
            })
            .catch((err) => {
                dispatch(
                    addNotification({
                        notification: {
                            type:
                                err.code === "ERR_BAD_REQUEST"
                                    ? "warning"
                                    : "error",
                            text:
                                err.code === "ERR_BAD_REQUEST"
                                    ? err.response.data.message
                                    : err.message,
                        },
                    })
                );
            })
            .finally(() => {
                triggerBtn.removeAttribute("disabled");
                triggerBtn.innerHTML = btnHtml;
            });
    };

    useEffect(() => {
        if (url) window.open(url);
    }, [url]);

    return (
        <div className="cart-product">
            <div className="cart-product__img-block">
                <img
                    src={book.ThumbnailPath}
                    alt=""
                    className="cart-product__img"
                />
            </div>
            <div className="cart-product__main">
                <span className="cart-product__author">
                    {book.Authors[0].PenName}
                </span>
                <h3
                    className="cart-product__name"
                    onClick={() => navigate(`/book/${book._id}`)}
                >
                    {book.Name}
                </h3>
                <div className="cart-product__price-block">
                    <span>{book.Price.toFixed(2)} USD</span>
                </div>
                {book.AmazonLink ? (
                    <a
                        href={book.AmazonLink}
                        target="_blank"
                        rel="noreferrer"
                        className="cart-product__link"
                    >
                        Available on Amazon
                    </a>
                ) : null}
            </div>
            <div className="cart-product__right">
                <div className="cart-product__btns">
                    <button
                        className="cart-product__btn"
                        onClick={downloadBook}
                    >
                        <IonIcon icon={downloadOutline} />
                    </button>
                </div>
                <div className="cart-product__right-text">
                    {purchasedBook.DownloadsLeft} downloads left
                </div>
            </div>
        </div>
    );
};

export default PurchasedBook;
