import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import { heart, heartOutline, trashOutline } from "ionicons/icons";

import { tRootState } from "../../store";
import { tBook } from "../../store/types/app.types";

import useFunctions from "../../hooks/useFunctions/useFunctions";
import { useNavigate } from "react-router-dom";

const CartBook = ({ book }: { book: tBook }) => {
    const navigate = useNavigate();

    const {
        wishlist,
        removedWishlist,
        addingWishlist,

        removedCart,
    } = useSelector((state: tRootState) => state.user);

    const { addToWishlist, removeFromWishlist, removeFromCart } =
        useFunctions();

    const inWishlist =
        (wishlist.findIndex((wishlistBook) => wishlistBook._id === book._id) !==
            -1 ||
            addingWishlist.findIndex(
                (wishlistBook) => wishlistBook._id === book._id
            ) !== -1) &&
        !removedWishlist.includes(book._id);

    const removed = removedCart.includes(book._id);

    if (removed) return null;

    return (
        <div className="cart-product">
            <div className="cart-product__img-block">
                <img
                    src={book.ThumbnailPath}
                    alt=""
                    className="cart-product__img"
                />
            </div>
            <div className="cart-product__main">
                <span className="cart-product__author">
                    {book.Authors[0].PenName}
                </span>
                <h3
                    className="cart-product__name"
                    onClick={() => navigate(`/book/${book._id}`)}
                >
                    {book.Name}
                </h3>
                <div className="cart-product__price-block">
                    <span>{book.Price.toFixed(2)} USD</span>
                </div>
                {book.AmazonLink ? (
                    <a
                        href={book.AmazonLink}
                        className="cart-product__link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Available on Amazon
                    </a>
                ) : null}
            </div>
            <div className="cart-product__right">
                <div className="cart-product__btns">
                    <button
                        className="cart-product__btn"
                        onClick={
                            inWishlist
                                ? (e) => removeFromWishlist(e, book)
                                : (e) => addToWishlist(e, book)
                        }
                    >
                        <IonIcon
                            icon={inWishlist ? heart : heartOutline}
                            className={inWishlist ? "text-tertiary" : ""}
                        />
                    </button>
                    <button
                        className="cart-product__btn"
                        onClick={(e) => removeFromCart(e, book)}
                    >
                        <IonIcon icon={trashOutline} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CartBook;
